import { Alert, Box, Button, Cards, Modal, SpaceBetween } from "@cloudscape-design/components";
import { downloadAsPDF, downloadAsPatchwork, downloadAsZip, downloadImage } from '../utils/export';

import ExpandableSection from "@cloudscape-design/components/expandable-section";
import { GenerationStep } from "./GenerationProgress";
import ImageComponent from "./ImageComponent";
import { Panel } from "../types/ai";
import Spinner from "@cloudscape-design/components/spinner";
import { useState } from "react";

interface StoryboardPanelProps {
  data: Panel[];
  regenImage: (index: number) => Promise<void>;
  imagesLoading: boolean[];
  error: string | null;
  generationStep: GenerationStep;
}

const StoryboardPanel = ({
  data,
  regenImage,
  imagesLoading,
  error,
  generationStep
}: StoryboardPanelProps) => {
  const [showExportModal, setShowExportModal] = useState(false);
  const [exporting, setExporting] = useState<string | null>(null);


  const handleExportImages = async () => {
    if (!data?.length) return;

    const missingImagePanel = data.find(panel => !panel.image);
    if (missingImagePanel) {
      throw new Error('All panels must have an image before exporting');
    }
    
    // Create an array of image data and their descriptions
    const exportData = data.map((panel, index) => ({
      imageData: panel.image as string,
      description: panel.simplePanel,
      fileName: `panel-${index + 1}.png`
    }));

    await downloadAsZip(exportData, 'storyboard-export');
  };

  const handleExportPDF = async () => {
    if (!data?.length) return;
    await downloadAsPDF(data, 'storyboard-export');
  };

  const handleExportPatchwork = async () => {
    if (!data?.length) return;
    try {
      const dataUrl = await downloadAsPatchwork(data, 'storyboard-export') || "";
      downloadImage(dataUrl, 'storyboard-export-patchwork.png');
    } catch (error) {
      console.error('Failed to create patchwork:', error);
      // Handle error (show notification, etc.)
    }
  };

  const displayPrompt = (prompt: string, type: any) => {
    const parsedPrompt = JSON.parse(prompt);
    return parsedPrompt[type]
  }

  const handleRegenClick = (index: number) => {
    regenImage(index)
  }

  const handleExport = async (exportFn: () => Promise<void>, format: string) => {
    try {
      setExporting(format);
      await exportFn();
      setShowExportModal(false);
    } catch (err) {
      console.error('Export failed:', err);
    } finally {
      setExporting(null);
    }
  };
  
  const exportOptions = [
    {
      id: "zip",
      title: "Images Package",
      subtitle: "ZIP Archive",
      description: "Download all panels as separate high-quality images with descriptions",
      icon: "📦",
      onClick: () => handleExport(handleExportImages, "ZIP")
    },
    {
      id: "pdf",
      title: "PDF Document",
      subtitle: "Multi-page Document",
      description: "Create a professional PDF with one panel per page, including descriptions",
      icon: "📄",
      onClick: () => handleExport(handleExportPDF, "PDF")
    },
    {
      id: "patchwork",
      title: "Patchwork Image",
      subtitle: "Single PNG Image",
      description: "Generate a single image showing all panels in a grid layout",
      icon: "🖼️",
      onClick: () => handleExport(handleExportPatchwork, "patchwork")
    }
  ];
  
  return (
    <div className="storyboard-container">
      {error && (
        <Alert
          type="error"
          dismissible
          header="Error"
        >
          {error}
        </Alert>
      )}

      {data && data.length > 0 &&(
        <>
        <Box margin={{ bottom: "l" }}>
          <Button
            variant="primary"
            iconName="download"
            onClick={() => setShowExportModal(true)}
          >
            Export Storyboard
          </Button>
        </Box>

        <Modal
          visible={showExportModal}
          onDismiss={() => setShowExportModal(false)}
          header="Export Storyboard"
          closeAriaLabel="Close export modal"
          size="medium"
        >
          <div className="export-modal-content">
              <div className="export-options-grid">
                {exportOptions.map((option) => (
                  <div 
                    key={option.id} 
                    className="export-option-card"
                    onClick={option.onClick}
                  >
                    <div className="export-option-content">
                      <span className="export-icon">{option.icon}</span>
                      <div className="export-text">
                        <h3>{option.title}</h3>
                        <span className="export-subtitle">{option.subtitle}</span>
                        <p>{option.description}</p>
                      </div>
                    </div>
                    <Button
                      variant="primary"
                      iconName="download"
                      loading={exporting === option.id}
                      fullWidth
                    >
                      Export
                    </Button>
                  </div>
                ))}
              </div>
            </div>
        </Modal>
      </>
      )}
      <div className="panels-grid">
        {data?.map((item, index) => (
          <div key={item.id} className="panel-container">
            <ImageComponent
              loading={imagesLoading[index]}
              image={item.image}
              img_version={item.img_version}
              onRegenClick={() => regenImage(index)}
              index={index}
            />
            <p>{item.simplePanel}</p>
            <ExpandableSection headerText="Prompts">
              {generationStep === 'generating-prompts' ? (
                <div className="prompt-loading">
                  <Spinner size="normal" />
                  <span>Generating prompt...</span>
                </div>
              ) : item?.prompt ? (
                <>
                  <p>
                    <strong>Positive:</strong> {displayPrompt(item.prompt, 'positive_prompt')}
                  </p>
                  <p>
                    <strong>Negative:</strong> {displayPrompt(item.prompt, 'negative_prompt')}
                  </p>
                </>
              ) : (
                <p>No prompt generated yet</p>
              )}
          </ExpandableSection>
          </div>
        ))}
      </div>
    </div>
  );
}

export default StoryboardPanel
import React, { createContext, useContext, useState } from 'react';
import { imageModels, imageStyles, textModels } from '../common/constants';

import { SelectedModels } from '../types/ai';

interface SettingsContextType {
  selectedModels: SelectedModels;
  selectedTemperature: number;
  numberOfPanels: number;
  experimental: boolean;
  setSelectedModels: (models: any) => void;
  setSelectedTemperature: (temp: number) => void;
  setNumberOfPanels: (num: number) => void;
  setExperimental: (exp: boolean) => void;
}

const SettingsContext = createContext<SettingsContextType | undefined>(undefined);

// TODO: change "any"
export const SettingsProvider = ({ children }: any) => {
  const [selectedModels, setSelectedModels] = useState({
    text: textModels[0],
    image: imageModels[0],
    style: imageStyles[0]
  });
  const [selectedTemperature, setSelectedTemperature] = useState(0.2);
  const [numberOfPanels, setNumberOfPanels] = useState(7);
  const [experimental, setExperimental] = useState(true);

  return (
    <SettingsContext.Provider value={{
      selectedModels,
      selectedTemperature,
      numberOfPanels,
      experimental,
      setSelectedModels,
      setSelectedTemperature,
      setNumberOfPanels,
      setExperimental
    }}>
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => {
  const context = useContext(SettingsContext);
  if (context === undefined) {
    throw new Error('useSettings must be used within a SettingsProvider');
  }
  return context;
};

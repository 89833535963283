// Text generation models
export const textModels = [
  // { label: "claude-3-sonnet", value: "anthropic.claude-3-sonnet-20240229-v1:0" },
  { label: "claude-3.5-Haiku CRI", value: "us.anthropic.claude-3-5-haiku-20241022-v1:0" },
  { label: "Nova Pro", value: "amazon.nova-pro-v1:0" }, // a highly capable multimodal model with the best combination of accuracy, speed, and cost for a wide range of tasks
  { label: "Nova Micro", value: "amazon.nova-micro-v1:0" }, //  a text only model that delivers the lowest latency responses at very low cost
  
  { label: "claude-3.5-sonnet v2 CRI", value: "us.anthropic.claude-3-5-sonnet-20241022-v2:0" },

  { label: "claude-3.5-sonnet CRI", value: "us.anthropic.claude-3-5-sonnet-20240620-v1:0"},

  { label: "claude-3-sonnet CRI", value: "us.anthropic.claude-3-sonnet-20240229-v1:0" },
  { label: "claude-3-haiku CRI", value: "us.anthropic.claude-3-haiku-20240307-v1:0" },
  { label: "claude-3-haiku", value: "anthropic.claude-3-haiku-20240307-v1:0" },
  // { label: "claude-3-opus CRI", value: "us.anthropic.claude-3-opus-20240229-v1:0" },
  
  { label: "claude-instant-v1", value: "anthropic.claude-instant-v1" },
  { label: "claude-v2:1", value: "anthropic.claude-v2:1" },
  { label: "claude-v2", value: "anthropic.claude-v2" },
  { label: "claude-v1", value: "anthropic.claude-v1" },
]

export const imageModels = [
  { label: "stability.stable-image-core-v1:0", value: "stability.stable-image-core-v1:0" },
  { label: "stability.sd3-large-v1:0", value: "stability.sd3-large-v1:0" },
  { label: "stability.stable-image-ultra-v1:0", value: "stability.stable-image-ultra-v1:0" },
  
  { label: "stability.stable-diffusion-xl-v1", value: "stability.stable-diffusion-xl-v1" },
  // { label: "stability.stable-diffusion-xl-v0", value: "stability.stable-diffusion-xl-v0" },
  // { label: "amazon.titan-image-generator-v1", value: "amazon.titan-image-generator-v1" },
]

export const imageStyles = [
  { label: "comic-book", style_preset: "comic-book", positive_prompt: "graphic illustration, comic art, graphic novel art, vibrant, highly detailed", negative_prompt: "photograph, deformed, glitch, noisy, realistic, stock photo, multiple scenes"},
  { label: "anime", positive_prompt: "anime artwork, anime style, key visual, vibrant, studio anime, highly detailed", style_preset: "anime", negative_prompt: "photo, deformed, black and white, realism, disfigured, low contrast"},
  { label: "digital-art", positive_prompt: "concept art, digital artwork, illustrative, painterly, matte painting, highly detailed", style_preset: "digital-art", negative_prompt: "photo, photorealistic, realism, ugly"},
  { label: "line-art", positive_prompt: "professional, sleek, modern, minimalist, graphic, line art, vector graphics", style_preset: "line-art", negative_prompt: "anime, photorealistic, 35mm film, deformed, glitch, blurry, noisy, off-center, deformed, cross-eyed, closed eyes, bad anatomy, ugly, disfigured, mutated, realism, realistic, impressionism, expressionism, oil, acrylic"},
  { label: "cinematic", positive_prompt: "", style_preset: "cinematic", negative_prompt: ""},
  { label: "3d-model", positive_prompt: "", style_preset: "3d-model", negative_prompt: ""},
  { label: "analog-film", positive_prompt: "", style_preset: "analog-film", negative_prompt: ""},
  { label: "photographic", positive_prompt: "", style_preset: "photographic", negative_prompt: ""},
  { label: "enhance", positive_prompt: "", style_preset: "enhance", negative_prompt: ""},
  { label: "fantasy-art", positive_prompt: "", style_preset: "fantasy-art", negative_prompt: ""},
  { label: "isometric", positive_prompt: "", style_preset: "isometric", negative_prompt: ""},
  { label: "low-poly", positive_prompt: "", style_preset: "low-poly", negative_prompt: ""},
  { label: "modeling-compound", positive_prompt: "", style_preset: "modeling-compound", negative_prompt: ""},
  { label: "neon-punk", positive_prompt: "", style_preset: "neon-punk", negative_prompt: ""},
  { label: "origami", positive_prompt: "", style_preset: "origami", negative_prompt: ""},
  { label: "pixel-art", positive_prompt: "", style_preset: "pixel-art", negative_prompt: ""},
  { label: "tile-texture", positive_prompt: "", style_preset: "tile-texture", negative_prompt: ""},
]

// Temperature range for text generation
export const temperatureRange = {
  min: 0,
  max: 1,
  step: 0.1,
};

// Default number of storyboard panels
export const defaultPanelCount = 7;

// Maximum allowed panels
export const maxPanelCount = 20;

export const SAMPLE_TEXTS = [
  {title: "🐕‍🦺 The dog ride", script: "The dog was ready to go on a walk.He went outside and had a great time on the hike. He thought, I am very happy to be in the field! After sometime, he went to find his human mom and she was so proud of him because of the gift he made her: a beautiful yellow flower. Then, they came back home and he thought: it was a beautiful day for me!"},
  {title: "✨ Maya the inventor", script: `Once upon a time, in a bustling city nestled between majestic mountains and an endless ocean, lived a young inventor named Maya. She had always been captivated by the boundless possibilities of technology and the wonders it could create. Her days were spent tinkering in her small workshop, dreaming up inventions that could change the world.
    Maya's biggest dream was to build a machine that could bring light to the darkest corners of the world. She envisioned a device powered by renewable energy that could provide electricity to remote villages, where children studied by candlelight and families gathered around fires in the evenings.
    
    With unyielding determination, Maya set out to create her invention. Days turned into weeks, weeks into months, and months into years. She faced numerous setbacks, encountered countless challenges, and often felt the weight of self-doubt creeping in.
    
    But Maya's spirit remained unbroken. She found inspiration in the resilience of nature and the unwavering support of her community. Whenever she gazed at the stars twinkling above the mountains, she was reminded of the infinite possibilities awaiting discovery.
    
    After years of tireless effort, Maya finally unveiled her invention—a compact, solar-powered generator capable of providing sustainable energy to remote areas. With its sleek design and efficient technology, it could harness sunlight during the day and illuminate the nights with a warm, radiant glow.
    
    Eager to share her creation with the world, Maya traveled to distant lands where her invention was needed most. She visited villages where the arrival of her machine brought tears of joy to the faces of children seeing electric light for the first time. Families gathered around it, sharing stories and experiences long after the sun had set, feeling the warmth of unity and hope it brought.
    
    Word of Maya's invention spread far and wide, capturing the attention of innovators, philanthropists, and leaders worldwide. Her creation sparked a movement, inspiring a wave of sustainable technologies and empowering communities globally.
    
    Maya's journey taught her that the most extraordinary accomplishments often stem from unwavering passion, resilience in the face of adversity, and a belief in the power of one's dreams. Her story became a beacon of hope, illustrating that even the smallest spark of an idea can illuminate the world with boundless light and inspiration.`
  },{
    title: "🧙 Chronicle of the eclipsed realm", script: `In a world where magic and technology coexist, an ancient prophecy foretells the coming of a rare celestial event known as the Grand Eclipse. This celestial phenomenon is said to open a portal to a mysterious realm beyond, a place untouched by time and filled with unimaginable power.
The protagonist, a young and skilled mage named Lyra, discovers that she is the chosen one destined to unlock the secrets of the Eclipsed Realm. As the Grand Eclipse approaches, dark forces from both magic and technology factions seek to control the power hidden within.
Lyra, guided by a wise but enigmatic mentor, embarks on a perilous journey to gather a diverse group of allies, each possessing unique abilities and backgrounds. Among her companions are a rogue technomancer, a nature-wielding druid, a cyborg with a forgotten past, and a historian with knowledge of ancient prophecies.
As the group delves deeper into the mysteries of the Eclipsed Realm, they must overcome treacherous landscapes, solve riddles left by the realm's ancient guardians, and face off against formidable foes fueled by the desire for ultimate power. Along the way, Lyra uncovers the true history of her lineage, intertwined with the very fabric of the Eclipsed Realm.
The story builds towards the climax during the night of the Grand Eclipse, where the fate of both the magical and technological worlds hangs in the balance. Lyra must make a choice between self-sacrifice and unleashing the power of the Eclipsed Realm to restore balance to the universe.
"Chronicles of the Eclipsed Realm" is an epic tale of magic, technology, destiny, and the enduring power of unity in the face of imminent darkness.`
  }
]
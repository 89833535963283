import Box from "@cloudscape-design/components/box";
// src/components/GenerationProgress.tsx
import ProgressBar from "@cloudscape-design/components/progress-bar";
import React from "react";

export type GenerationStep = 
  | 'idle'
  | 'generating-story'
  | 'generating-panels'
  | 'generating-prompts'
  | 'generating-images'
  | 'complete';

interface GenerationProgressProps {
  currentStep: GenerationStep;
  totalPanels: number;
  completedPanels: number;
}

const GenerationProgress: React.FC<GenerationProgressProps> = ({
  currentStep,
  totalPanels,
  completedPanels,
}) => {
  const getProgressPercentage = () => {
    switch (currentStep) {
      case 'idle':
        return 0;
      case 'generating-story':
        return 20;
      case 'generating-panels':
        return 40;
      case 'generating-prompts':
        return 60;
      case 'generating-images':
        return 60 + ((completedPanels / totalPanels) * 40);
      case 'complete':
        return 100;
      default:
        return 0;
    }
  };


  const getStatusText = () => {
    switch (currentStep) {
      case 'idle':
        return "Ready to generate";
      case 'generating-story':
        return "Analyzing your story...";
      case 'generating-panels':
        return "Breaking down into panels...";
      case 'generating-prompts':
        return "Generating image prompts...";  // New status text
      case 'generating-images':
        return `Generating images (${completedPanels}/${totalPanels})...`;
      case 'complete':
        return "Generation complete!";
      default:
        return "";
    }
  };

  return (
    <Box margin={{ bottom: "l" }}>
      <ProgressBar
        value={getProgressPercentage()}
        label="Generation progress"
        description={getStatusText()}
        status={currentStep === 'complete' ? 'success' : 'in-progress'}
      />
    </Box>
  );
};

export default GenerationProgress;

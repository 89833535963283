import './App.css';
import './styles/components.css';

import { ContentLayout, TopNavigation } from '@cloudscape-design/components';
import { useCallback, useMemo, useState } from 'react';

import { CharacterProvider } from './contexts/CharacterContext';
import Layout from './components/Layout';
import { SettingsProvider } from './contexts/SettingsContext';
import { StoryboardProvider } from './contexts/StoryboardContext';
import { useAuth } from './contexts/AuthContext';

function App() {
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const { user, userAttributes, loading, signOut } = useAuth();

  const handleUtilityClick = useCallback((e: { detail: { id: string } }) => {
    if (e.detail.id === 'logout') {
      console.log('logging out');
      signOut();
    }
  }, [signOut]);

  const memoizedTopNavigation = useMemo(() => (
    <TopNavigation
      identity={{
        href: "#",
        title: "GenAI Storyboard Generator",
        logo: {
          src: "/Storyboard.svg",
          alt: "Logo"
        }
      }}
      utilities={[
        {
          type: "button",
          text: "Learn more",
          href: "https://w.amazon.com/bin/view/Users/fclanet/storyboardgen/",
          external: true,
          externalIconAriaLabel: "(opens in a new tab)"
        },
        {
          type: "button",
          text: `v${import.meta.env.PACKAGE_VERSION || '0.0.0'}`,
        },
        {
          type: "button",
          iconName: "settings",
          ariaLabel: "Settings",
          title: "Settings",
          onClick: () => setIsSettingsModalOpen(true)
        },
        {
          type: "menu-dropdown",
          text: userAttributes ? (`${userAttributes.given_name} ${userAttributes.family_name}`) : ("Not connected"),
          description: userAttributes ? userAttributes.email : "",
          iconName: "user-profile",
          items: [
            { id: "logout", text: "Log out" }
          ],
          onItemClick: handleUtilityClick
        }
      ]}
    />
  ), [userAttributes, handleUtilityClick]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <SettingsProvider>
      <StoryboardProvider>
        <CharacterProvider>
          <>
            {memoizedTopNavigation}
            <ContentLayout>
              {user ? (
                <div className="App">
                  <div className='AppContent'>
                    <Layout 
                      user={user}
                      isSettingsModalOpen={isSettingsModalOpen}
                      setIsSettingsModalOpen={setIsSettingsModalOpen}
                    />
                  </div>
                </div>
              ) : (
                <p>You need to log in first.</p>
              )}
            </ContentLayout>
          </>
        </CharacterProvider>
      </StoryboardProvider>
    </SettingsProvider>
  );
}

export default App;

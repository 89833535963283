import * as React from "react";

import { imageModels, imageStyles, textModels } from '../common/constants';
import { useCallback, useEffect, useState } from 'react';

import Button from "@cloudscape-design/components/button";
import CharacterManager from "./CharacterManager";
import Container from "@cloudscape-design/components/container";
import ExpandableSection from "@cloudscape-design/components/expandable-section";
import Header from "@cloudscape-design/components/header";
import MainContent from "./MainContent"
import { SAMPLE_TEXTS } from "../common/constants";
import SampleTextButtons from "./SampleTextButtons";
import SettingsModal from "./SettingsModal";
import StoryboardPanel from "./StoryboardPanel";
import TextPanelsDisplay from "./TextPanelsDisplay";
import { useCharacters } from "../contexts/CharacterContext";
import { useSettings } from "../contexts/SettingsContext";
import { useStoryboard } from "../contexts/StoryboardContext";

// TODO: change "any"
const Layout = (props: any) => {
  
  const {
    selectedModels,
    selectedTemperature,
    numberOfPanels,
    experimental,
    setSelectedModels,
    setSelectedTemperature,
    setNumberOfPanels,
    setExperimental
  } = useSettings();

  const {
    data,
    loading,
    error,
    setError,
    generateStory,
    regenerateImage,
    generateImagePrompt,
    imagesLoading,
    setImageLoading,
    setImageData,
    setData,
    setLoading,
    generationStep,
    setGenerationStep,
    completedPanels
  } = useStoryboard();

  const { 
    characters, 
    isCharacterSectionExpanded, 
    setCharacters, 
    setIsCharacterSectionExpanded,
    generateCharacters 
  } = useCharacters();
  const [customText, setCustomText] = useState(SAMPLE_TEXTS[0].script);

  
  const generate = async (story = "", generateNewCharacters = true) => {
    try {
      setError(null);
      let rawStory = story;
      let generatedStory = "";
      let storyCharacters = characters;
      
      setData([]);
      if(generateNewCharacters || characters.length === 0) {
        setGenerationStep('generating-story')
        storyCharacters = await generateCharacters(rawStory);
      }
      if(rawStory === "") {
        rawStory = customText;
      }

      console.debug(`generateNewCharacters: ${generateNewCharacters}`);
      console.debug(`rawStory: ${rawStory}`);
      
      await generateStory(rawStory, storyCharacters);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred while generating the story')
    }
  };

  // Effect to automatically expand the Character Manager when there is at least one character
  useEffect(() => {
    if (characters.length > 0) {
      setIsCharacterSectionExpanded(true);
    }
  }, [characters]);

  const handleModelChange = useCallback((modelType: any, newModel: any) => {
    setSelectedModels((prev: any) => ({ ...prev, [modelType]: newModel }));
  }, []);

  return (
    <>
    <Container
      fitHeight
      header={
        <Header
        variant="h2"
        description="Let's start by creating an awesome story!"
        >
          Storyboard manager
        </Header>
      }>
        <SettingsModal
          isOpen={props.isSettingsModalOpen}
          onClose={() => props.setIsSettingsModalOpen(false)}
          selectedModels={selectedModels}
          onModelChange={handleModelChange}
          temperature={selectedTemperature}
          onTemperatureChange={setSelectedTemperature}
          numberOfPanels={numberOfPanels}
          onNumberOfPanelsChange={setNumberOfPanels}
          experimental={experimental}
          onExperimentalChange={setExperimental}
        />
        <MainContent
          customText={customText}
          setCustomText={setCustomText}
          characters={characters}
          setCharacters={setCharacters}
          isCharacterSectionExpanded={isCharacterSectionExpanded}
          setIsCharacterSectionExpanded={setIsCharacterSectionExpanded}
          selectedModels={selectedModels}
          selectedTemperature={selectedTemperature}
          data={data}
          setData={setData}
          loading={loading}
          imagesLoading={imagesLoading}
          generate={generate}
          regenerateImage={regenerateImage}
          error={error}
          setError={setError}
          generationStep={generationStep}
          completedPanels={completedPanels}
        />
        <ExpandableSection
          headerText="More options..."
        >
          <p>Coming soon...</p>
        </ExpandableSection>
      </Container>
    </>
  )

};

export default Layout;
import * as React from "react";

import Button from "@cloudscape-design/components/button"
import Textarea from "@cloudscape-design/components/textarea";
import { useState } from 'react';

// TODO: change "any"
const CharacterItem = (props: any) => {

  const [isEditing, setIsEditing] = useState(false);
  const [nextCharacterDescription, setNextCharacterDescription] = useState(props.character.description);

  const handleDescriptionChange = (e: any) => {
    setNextCharacterDescription(e.detail.value);
  }

  const abortCharacterChange = () => {
    setNextCharacterDescription(props.character.description);
    setIsEditing(false);
  }

  const validateCharacterChange = () => {
    props.updateCharacter(props.character.id, {description: nextCharacterDescription})
    setIsEditing(false);
  }

  return (
    <li>
      <h3>{props.character.name}</h3>
      <div>
        <legend>Description:</legend>
        {isEditing ? (
          <>
            <Textarea
                      value={nextCharacterDescription}
                      onChange={handleDescriptionChange}
                      placeholder="Enter custom text here..."
              />
          <div>
            <Button variant="icon" iconName="redo" aria-label="Back" onClick={() => abortCharacterChange()}  >
                Go Back
            </Button>
            <Button variant="icon" iconName="check" aria-label="Validate" onClick={() => validateCharacterChange()}  >
                Validate change
            </Button>
          </div>
          </>
        ) : (
          <>
            <p>{props.character.description}</p>
            <div>
              <Button variant="icon" iconName="edit" aria-label="Edit" onClick={() => setIsEditing(true)}  >
                  Edit
              </Button>
            </div>
          </>
        )}
      </div>
      
    </li>
  );
};

export default CharacterItem;
interface Config {
  region: string;
  cognito: {
    userPoolId: string;
    userPoolWebClientId: string;
    oauth: {
      domain: string;
      scope: string[];
      redirectSignIn: string;
      redirectSignOut: string;
      responseType: string;
      providers: string[];
    };
  };
  api: {
    endpoints: Array<{
      name: string;
      endpoint: string;
      region: string;
    }>;
  };
}

// Get environment variables
const isLocalDev = import.meta.env.MODE === 'development.local';
const envPrefix = import.meta.env.MODE === 'production' ? '' : 'dev.';
const domain = isLocalDev 
  ? 'http://localhost:3000'
  : `https://${envPrefix}storyboardgen.fclanet.people.aws.dev`;

console.log('Environment configuration:', {
  mode: import.meta.env.MODE,
  isLocalDev,
  domain,
  redirectSignIn: `${domain}/login`
});

export const config: Config = {
  region: import.meta.env.VITE_AWS_REGION as string,
  cognito: {
    userPoolId: import.meta.env.VITE_COGNITO_USER_POOL_ID as string,
    userPoolWebClientId: import.meta.env.VITE_COGNITO_CLIENT_ID as string,
    oauth: {
      domain: import.meta.env.VITE_COGNITO_DOMAIN as string,
      scope: ["openid", "profile", "email"],
      redirectSignIn: `${domain}/login`,
      redirectSignOut: import.meta.env.VITE_REDIRECT_SIGN_OUT as string,
      responseType: "token",  // Keep using token flow for now
      providers: ["Federate"]
    }
  },
  api: {
    endpoints: [
      {
        name: "genai",
        endpoint: import.meta.env.VITE_API_ENDPOINT as string,
        region: import.meta.env.VITE_AWS_REGION as string
      }
    ]
  }
};

import { Box, Button, Modal, SpaceBetween } from '@cloudscape-design/components';

import ModelsOptions from './ModelsOptions';
import React from 'react';

function SettingsModal({ isOpen, onClose, ...props }: any) {
  return (
    <Modal
      onDismiss={onClose}
      visible={isOpen}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onClose}>Cancel</Button>
            <Button variant="primary" onClick={onClose}>Ok</Button>
          </SpaceBetween>
        </Box>
      }
      header="Settings"
    >
      {/* <div className="text-panels-display" id="model-options"> */}
        <ModelsOptions 
          selectedModels={props.selectedModels}
          onModelChange={props.onModelChange}
          temperature={props.temperature}
          onTemperatureChange={props.onTemperatureChange}
          numberOfPanels={props.numberOfPanels}
          onNumberOfPanelsChange={props.onNumberOfPanelsChange}
          experimental={props.experimental}
          onExperimentalChange={props.onExperimentalChange}
        />
      {/* </div> */}
    </Modal>
  );
}

export default SettingsModal;

// src/components/MainContent.tsx
import * as React from "react";

import { Character, Panel, SelectedModels } from "../types/ai";

import { Alert } from "@cloudscape-design/components";
import CharacterManager from "./CharacterManager";
import ExpandableSection from "@cloudscape-design/components/expandable-section";
import { GenerationStep } from "./GenerationProgress";
import SampleTextButtons from "./SampleTextButtons";
import StoryboardPanel from "./StoryboardPanel";
import TextPanelsDisplay from "./TextPanelsDisplay";

interface MainContentProps {
  customText: string;
  setCustomText: (text: string) => void;
  characters: Character[];
  setCharacters: (characters: Character[]) => void;
  isCharacterSectionExpanded: boolean;
  setIsCharacterSectionExpanded: (expanded: boolean) => void;
  selectedModels: SelectedModels;
  selectedTemperature: number;
  data: Panel[];
  setData: (data: Panel[]) => void;
  loading: boolean;
  imagesLoading: boolean[];
  generate: (story?: string, generateNewCharacters?: boolean) => Promise<void>;
  regenerateImage: (index: number) => Promise<void>;
  error: string | null;
  setError: (error: string | null) => void;
  generationStep: GenerationStep;
  completedPanels: number;
}

const MainContent = ({
  customText,
  setCustomText,
  characters,
  setCharacters,
  isCharacterSectionExpanded,
  setIsCharacterSectionExpanded,
  selectedModels,
  selectedTemperature,
  data,
  setData,
  loading,
  imagesLoading,
  generate,
  regenerateImage,
  error,
  setError,
  generationStep,
  completedPanels,
}: MainContentProps) => {
  return (
    <div className="text-panels-display" id="text-panel">
      {error && (
        <Alert
          type="error"
          dismissible
          onDismiss={() => setError(null)}
        >
          {error}
        </Alert>
      )}
      <SampleTextButtons onSelectText={setCustomText} />
      <div className="data-panel">
        <TextPanelsDisplay
          customText={customText}
          setCustomText={setCustomText}
          characters={characters}
          setCharacters={setCharacters}
          data={data}
          setData={setData}
          generate={generate}
          loading={loading}
          imagesLoading={imagesLoading}
          error={error}
          setError={setError}
          generationStep={generationStep}
          completedPanels={completedPanels}
        />
        <ExpandableSection
          headerText={`Characters (${characters.length})`}
          expanded={isCharacterSectionExpanded}
          onChange={({ detail }) => setIsCharacterSectionExpanded(detail.expanded)}
        >
          <CharacterManager 
            characters={characters}
            customText={customText}
            selectedTextModel={selectedModels.text}
            selectedTemperature={selectedTemperature}
            setCharacters={setCharacters}
            generate={generate}
          />
        </ExpandableSection>
        <ExpandableSection headerText="Storyboard...">
          <StoryboardPanel
            data={data}
            regenImage={regenerateImage}
            imagesLoading={imagesLoading}
            error={error}
            generationStep={generationStep}
          />
        </ExpandableSection>
      </div>
    </div>
  );
};

export default MainContent;

import { API, Auth } from 'aws-amplify';

import { useAuth } from '../contexts/AuthContext';

const GENAI_API_NAME = "genai"
const ENDPOINTS = {
  EXTRACT_CHARACTERS: () => `extract-characters`,
  GENERATE_PANELS: () => `generate-panels`,
  GENERATE_IMAGE_PROMPTS: () => `generate-image-prompts`,
  GENERATE_IMAGE: () => `generate-image`,
};

// Create a hook-based API manager
export const useApiManager = () => {
  const getInit = async () => {
    try {
      const session = await Auth.currentSession();
      const idToken = session.getIdToken().getJwtToken();
      return {
        headers: { Authorization: `Bearer ${idToken}` },
        body: {}
      };
    } catch (error) {
      console.error('Error getting auth session:', error);
      throw error;
    }
  };

  return {
    extractCharacters: async (body: any) => {
      const init = await getInit();
      init.body = body;
      return API.post(GENAI_API_NAME, ENDPOINTS.EXTRACT_CHARACTERS(), init);
    },
    
    generatePanels: async (body: any) => {
      const init = await getInit();
      init.body = body;
      return API.post(GENAI_API_NAME, ENDPOINTS.GENERATE_PANELS(), init);
    },
    
    generateImagePrompts: async (body: any) => {
      const init = await getInit();
      init.body = body;
      return API.post(GENAI_API_NAME, ENDPOINTS.GENERATE_IMAGE_PROMPTS(), init);
    },
    
    generateImage: async (body: any) => {
      const init = await getInit();
      init.body = body;
      return API.post(GENAI_API_NAME, ENDPOINTS.GENERATE_IMAGE(), init);
    }
  };
};

// Export a default instance for backward compatibility
const ApiManager = {
  extractCharacters: async (body: any) => {
    const api = useApiManager();
    return api.extractCharacters(body);
  },
  generatePanels: async (body: any) => {
    const api = useApiManager();
    return api.generatePanels(body);
  },
  generateImagePrompts: async (body: any) => {
    const api = useApiManager();
    return api.generateImagePrompts(body);
  },
  generateImage: async (body: any) => {
    const api = useApiManager();
    return api.generateImage(body);
  }
};

export default ApiManager;

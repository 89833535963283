import { Character, Panel } from "../types/ai";
import GenerationProgress, { GenerationStep } from './GenerationProgress';

import Alert from "@cloudscape-design/components/alert";
import Button from "@cloudscape-design/components/button"
import Spinner from "@cloudscape-design/components/spinner";
import Textarea from "@cloudscape-design/components/textarea";
import { useEffect } from 'react';
import { useSettings } from '../contexts/SettingsContext';

interface TextPanelsDisplayProps {
    customText: string;
    setCustomText: (text: string) => void;
    characters: Character[];
    setCharacters: (characters: Character[]) => void;
    data: Panel[];
    setData: (data: Panel[]) => void;  // Added this prop
    generate: (story?: string, generateNewCharacters?: boolean) => Promise<void>;
    loading: boolean;
    imagesLoading: boolean[];
    error: string | null;
    setError: (error: string | null) => void;
    generationStep: GenerationStep;
    completedPanels: number;
  }
  
const TextPanelsDisplay = ({
    customText,
    setCustomText,
    characters,
    setCharacters,
    data,
    setData,
    generate,
    loading,
    imagesLoading,
    error,
    setError,
    generationStep,
    completedPanels,
  }: TextPanelsDisplayProps) => {

    const { numberOfPanels } = useSettings();

    const handleCustomTextChange = (event: { detail: { value: string; }; }) => {
        setCustomText(event.detail.value);
    }
    const handleFetchData = async () => {
        try {
            setError(null);
            setData([])
            setCharacters([])
            await generate(customText);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An error occurred while generating the story');
        }
    }
    useEffect(() => {
    }, [data, imagesLoading])
    
    return (
            <>
                <div>
                    {error && (
                        <Alert
                            type="error"
                            dismissible
                            onDismiss={() => setError(null)}
                        >
                            {error}
                        </Alert>
                    )}
                </div>
                <Textarea
                    className="custom-text"
                    value={customText}
                    onChange={handleCustomTextChange}
                    placeholder="Enter custom text here..."
                />

                {characters.length<1 && data.length<1 && loading ? (<>
                    <span aria-live="polite" aria-label="Loading table content.">
                        <Button loading variant="primary">Generating storyboard...</Button>
                    </span>
                </>
                ) : (<>
                        <Button onClick={handleFetchData} iconName="gen-ai">
                            Send your story
                        </Button>
                        <div>
                        </div>
                    </>
                    )}
                {loading && (
                    <div className="loading-container">
                        <Spinner size="large" />
                        <span>Generating your storyboard...</span>
                    </div>
                )}
                {(loading || generationStep !== 'idle') && (
                    <GenerationProgress
                    currentStep={generationStep}
                    totalPanels={numberOfPanels}
                    completedPanels={completedPanels}
                    />
                )}
                    
            </>

    );
};

export default TextPanelsDisplay;
import JSZip from 'jszip';
import { jsPDF } from 'jspdf';

interface ExportImageData {
  imageData: string;
  description: string;
  fileName: string;
}

export const downloadAsZip = async (
  exportData: ExportImageData[],
  zipName: string
) => {
  const zip = new JSZip();

  // Add images to zip
  exportData.forEach(({ imageData, fileName }) => {
    // Remove data:image/png;base64, prefix
    const base64Data = imageData.replace(/^data:image\/\w+;base64,/, "");
    zip.file(fileName, base64Data, { base64: true });
  });

  // Add description text file
  const description = exportData
    .map((data, index) => `Panel ${index + 1}: ${data.description}`)
    .join('\n\n');
  zip.file('descriptions.txt', description);

  // Generate and download zip
  const content = await zip.generateAsync({ type: 'blob' });
  const url = window.URL.createObjectURL(content);
  const link = document.createElement('a');
  link.href = url;
  link.download = `${zipName}.zip`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};

export const downloadAsPDF = async (
  data: any[],
  fileName: string
) => {
  const pdf = new jsPDF('p', 'mm', 'a4');
  const pageWidth = pdf.internal.pageSize.getWidth();
  const pageHeight = pdf.internal.pageSize.getHeight();
  const margin = 10;
  const imageWidth = pageWidth - (margin * 2);
  const imageHeight = imageWidth * 0.75; // 4:3 aspect ratio

  data.forEach((panel, index) => {
    if (index > 0) {
      pdf.addPage();
    }

    // Add image
    pdf.addImage(
      panel.image,
      'PNG',
      margin,
      margin,
      imageWidth,
      imageHeight
    );

    // Add description
    pdf.setFontSize(12);
    const textLines = pdf.splitTextToSize(
      panel.simplePanel,
      pageWidth - (margin * 2)
    );
    pdf.text(textLines, margin, imageHeight + margin + 10);

    // Add panel number
    pdf.setFontSize(10);
    pdf.text(
      `Panel ${index + 1}/${data.length}`,
      margin,
      pageHeight - margin
    );
  });

  pdf.save(`${fileName}.pdf`);
};


// src/utils/export.ts
export const downloadAsPatchwork = async (
  data: any[],
  fileName: string
) => {
  // Create a temporary canvas to compose the patchwork
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (!ctx) return;

  // Calculate grid dimensions with a maximum of 3 columns
  const MAX_COLUMNS = 3;
  const columns = Math.min(MAX_COLUMNS, Math.ceil(Math.sqrt(data.length)));
  const rows = Math.ceil(data.length / columns);
  
  // Set individual panel size
  const panelWidth = 300;
  const panelHeight = 225;
  const padding = 20;
  
  // Set canvas size for the entire patchwork including padding
  canvas.width = (panelWidth * columns) + (padding * (columns + 1));
  canvas.height = (panelHeight * rows) + (padding * (rows + 1));
  
  // Fill background
  ctx.fillStyle = '#f8f8f8';
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  // Process images in smaller batches
  const BATCH_SIZE = 6;
  for (let i = 0; i < data.length; i += BATCH_SIZE) {
    const batch = data.slice(i, i + BATCH_SIZE);
    
    await Promise.all(batch.map(async (panel, batchIndex) => {
      const index = i + batchIndex;
      try {
        // Create a temporary canvas for image scaling
        const tempCanvas = document.createElement('canvas');
        const tempCtx = tempCanvas.getContext('2d');
        if (!tempCtx) return;

        // Load image
        const img = new Image();
        await new Promise((resolve, reject) => {
          img.onload = resolve;
          img.onerror = reject;
          // Remove the data URL prefix if it exists
          const imageData = panel.image.startsWith('data:') 
            ? panel.image 
            : `data:image/png;base64,${panel.image}`;
          img.src = imageData;
        });

        // Scale image in temporary canvas with smoothing
        tempCanvas.width = panelWidth;
        tempCanvas.height = panelHeight;
        tempCtx.imageSmoothingEnabled = true;
        tempCtx.imageSmoothingQuality = 'high';
        tempCtx.drawImage(img, 0, 0, panelWidth, panelHeight);

        // Calculate position in grid with padding
        const col = index % columns;
        const row = Math.floor(index / columns);
        const x = (col * panelWidth) + (padding * (col + 1));
        const y = (row * panelHeight) + (padding * (row + 1));

        // Draw panel background
        ctx.fillStyle = 'white';
        ctx.fillRect(x, y, panelWidth, panelHeight);

        // Draw scaled image to main canvas
        ctx.drawImage(tempCanvas, x, y);

        // Draw panel border
        ctx.strokeStyle = '#e0e0e0';
        ctx.lineWidth = 2;
        ctx.strokeRect(x, y, panelWidth, panelHeight);
        
        // Draw panel number badge
        const badgeSize = 30;
        const badgePadding = 10;
        ctx.fillStyle = 'rgba(0, 0, 0, 0.7)';
        ctx.beginPath();
        ctx.roundRect(
          x + badgePadding, 
          y + badgePadding, 
          badgeSize, 
          badgeSize, 
          5
        );
        ctx.fill();
        
        // Draw panel number
        ctx.fillStyle = 'white';
        ctx.font = 'bold 16px Arial';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(
          `${index + 1}`, 
          x + badgePadding + (badgeSize / 2), 
          y + badgePadding + (badgeSize / 2)
        );

      } catch (error) {
        console.error(`Error processing panel ${index + 1}:`, error);
      }
    }));
  }

  // Instead of creating a download link directly, return the canvas data
  return new Promise<string>((resolve, reject) => {
    try {
      // Convert canvas to base64 string
      const dataUrl = canvas.toDataURL('image/png', 0.7);
      resolve(dataUrl);
    } catch (error) {
      reject(error);
    }
  });
};

// Create a separate function for handling the download
export const downloadImage = (dataUrl: string, fileName: string) => {
  // Create a blob from the data URL
  const byteString = atob(dataUrl.split(',')[1]);
  const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  
  const blob = new Blob([ab], { type: mimeString });
  const url = URL.createObjectURL(blob);
  
  // Create a temporary link and trigger download
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};






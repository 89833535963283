import { Box, SpaceBetween } from "@cloudscape-design/components";
import React, { useState } from "react";

import Button from "@cloudscape-design/components/button";
import { SAMPLE_TEXTS } from "../common/constants";

interface SampleTextButtonsProps {
  onSelectText: (text: string) => void;
}

const SampleTextButtons = ({ onSelectText }: SampleTextButtonsProps) => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  
  return (
    <SpaceBetween size="l">
      <div>
        <Box variant="h2">Sample Scripts</Box>
        <Box variant="p" color="text-body-secondary">
          Choose from our pre-written samples or enter your own script below
        </Box>
      </div>
      
      <Box padding={{ vertical: 'xs' }}>
        <SpaceBetween 
          direction="horizontal" 
          size="xs"
          className="sample-buttons-container"
        >
          {SAMPLE_TEXTS.map((text, index) => (
            <Button
              variant={selectedIndex === index ? "primary" : "normal"}
              key={index}
              onClick={() => {
                setSelectedIndex(index);
                onSelectText(text.script);
              }}
              iconAlign="right"
              className="sample-button"
            >
              {text.title}
            </Button>
          ))}
        </SpaceBetween>
      </Box>
    </SpaceBetween>
  );
};

export default SampleTextButtons;

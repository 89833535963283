import React, { ReactNode, createContext, useCallback, useContext, useState } from 'react';

import { GenerationStep } from '../components/GenerationProgress';
import { Panel } from '../types/ai';
import { useApiManager } from '../components/ApiManager';
import { useSettings } from './SettingsContext';

interface StoryboardContextType {
  data: Panel[];
  setData: React.Dispatch<React.SetStateAction<Panel[]>>;
  loading: boolean;
  error: string | null;
  setError: (error: string | null) => void;
  imagesLoading: boolean[];
  generateStory: (story: string, characters: any[]) => Promise<void>;
  regenerateImage: (index: number) => Promise<void>;
  generateImagePrompt: (index: number) => Promise<void>;
  setImageLoading: (index: number, isLoading: boolean) => void;
  setImageData: (index: number, image: string) => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  generationStep: GenerationStep;
  setGenerationStep: (step: GenerationStep) => void;
  completedPanels: number;
}

interface StoryboardProviderProps {
  children: ReactNode;
}

const StoryboardContext = createContext<StoryboardContextType | undefined>(undefined);

export const StoryboardProvider = ({ children }: StoryboardProviderProps) => {
  const [data, setData] = useState<Panel[]>([]);
  const [loading, setLoading] = useState(false);
  const [imagesLoading, setImagesLoading] = useState<boolean[]>([]);
  const { selectedModels, selectedTemperature, experimental, numberOfPanels } = useSettings();
  const [error, setError] = useState<string | null>(null);
  const [generationStep, setGenerationStep] = useState<GenerationStep>('idle');
  const [completedPanels, setCompletedPanels] = useState(0);
  const api = useApiManager();

  const getSdPromptData = useCallback(async (story: string, panels: any[], characters: any[], prompt_version = "1") => {
    if (experimental) {
      prompt_version = "2";
    }
    try {
      const apiResponse = await api.generateImagePrompts({
        story: story,
        panel: panels,
        model_id_text: selectedModels.text.value,
        characters: characters,
        style: selectedModels.style,
        prompt_version: prompt_version
      });
      return apiResponse;
    } catch (err) {
      console.error(`Error fetching data: ${err}`);
      throw err;
    }
  }, [api, experimental, selectedModels.text.value, selectedModels.style]);

  const generateImageForPanel = useCallback(async (item: any) => {
    console.log(`Generating image for panel ${item.panel}`);
    console.log(`panel: ${JSON.stringify(item.prompt)}`);
    
    try {
      const response = await api.generateImage({
        panel: item.panel,
        sd_prompts: JSON.parse(item.prompt),
        model_id_image: selectedModels.image.value,
        style: selectedModels.style
      });
      return response;
    } catch (error) {
      console.error(`Error generating image: ${error}`);
      throw error;
    }
  }, [api, selectedModels.image.value, selectedModels.style]);

  const generateStoryStandard = useCallback(async (story: string, characters: any[]) => {
    // Get panels data
    const response = await api.generatePanels({
      text: story,
      model_id_text: selectedModels.text.value,
      characters: characters,
      temperature: selectedTemperature,
      nb_panels: numberOfPanels
    });
    
    const panelsData = JSON.parse(response.body);
    console.log('Panels data received:', panelsData);
    const simplePanels = panelsData.panels.map((panel: any) => panel.simple_panel);

    // Create initial panel data
    const newData = panelsData.panels.map((panel: any, index: number) => ({
      id: index,
      panel: panel,
      simplePanel: panelsData.simplePanels[index],
      prompt: null,
      image: "",
      loading: false,
      img_version: 0
    }));

    setData(newData);

    // Generate SD prompts for all panels
    console.log(`Generating ALL sd_prompts for panels`);
    console.log(`panels: ${JSON.stringify(simplePanels)}`);
    const promptResponse = await getSdPromptData(story, panelsData.panels, characters, "1");
    const responseBody = JSON.parse(promptResponse.body);
    const prompts = JSON.parse(responseBody.sd_prompt).sd_prompts;

    // Update data with prompts
    const updatedData = [...newData];
    for (let i = 0; i < updatedData.length; i++) {
      updatedData[i].prompt = JSON.stringify({
        positive_prompt: prompts[i].positive_prompt,
        negative_prompt: prompts[i].negative_prompt
      });
    }

    setData(updatedData);

    // Generate images for all panels
    for (let i = 0; i < updatedData.length; i++) {
      setImageLoading(i, true);
      try {
        const image = await generateImageForPanel(updatedData[i]);
        // Don't parse the image response as JSON since it's a base64 string
        setImageData(i, image.body);
      } catch (error) {
        console.error(`Error generating image for panel ${i}:`, error);
      } finally {
        setImageLoading(i, false);
      }
    }
  }, [api, selectedModels.text.value, selectedTemperature, numberOfPanels, getSdPromptData, generateImageForPanel]);

  const generateStoryExperimental = useCallback(async (story: string, characters: any[]) => {
    // Get panels data
    setGenerationStep('generating-panels');
    const response = await api.generatePanels({
      text: story,
      model_id_text: selectedModels.text.value,
      characters: characters,
      temperature: selectedTemperature,
      nb_panels: numberOfPanels
    });
    
    const panelsData = JSON.parse(response.body);
    console.log('Panels data received (experimental):', panelsData);

    // Create initial panel data
    const newData = panelsData.panels.map((panel: any, index: number) => ({
      id: index,
      panel: panel,
      simplePanel: panelsData.simplePanels[index],
      prompt: null,
      image: "",
      loading: false,
      img_version: 0
    }));

    console.log('Processed panel data (experimental):', newData);
    setData(newData);

    // Generate SD prompts for all panels using version 2
    setGenerationStep('generating-prompts');
    const promptResponse = await getSdPromptData(story, panelsData.panels, characters, "2");
    const responseBody = JSON.parse(promptResponse.body);
    const prompts = responseBody.sd_prompt.sd_prompts;

    // Update data with prompts
    const updatedData = [...newData];
    for (let i = 0; i < updatedData.length; i++) {
      updatedData[i].prompt = JSON.stringify({
        positive_prompt: prompts[i].positive_prompt,
        negative_prompt: prompts[i].negative_prompt
      });
    }

    setData(updatedData);

    // Generate images for all panels
    setGenerationStep('generating-images');
    setCompletedPanels(0);
    for (let i = 0; i < updatedData.length; i++) {
      setImageLoading(i, true);
      try {
        const image = await generateImageForPanel(updatedData[i]);
        // Don't parse the image response as JSON since it's a base64 string
        setImageData(i, image.body);
        setCompletedPanels(i + 1);
      } catch (error) {
        console.error(`Error generating image for panel ${i}:`, error);
        setGenerationStep('idle');
      } finally {
        setImageLoading(i, false);
      }
    }
    setGenerationStep('complete');
  }, [api, selectedModels.text.value, selectedTemperature, numberOfPanels, getSdPromptData, generateImageForPanel]);

  const generateStory = useCallback(async (story: string, characters: any[]) => {
    try {
      setLoading(true);
      setError(null);
      setGenerationStep('generating-story');
      
      if (experimental) {
        await generateStoryExperimental(story, characters);
      } else {
        await generateStoryStandard(story, characters);
      }
    } catch (error) {
      setError(error instanceof Error ? error.message : 'An error occurred while generating the story');
      console.error('Error generating story:', error);
    } finally {
      setLoading(false);
    }
  }, [experimental, generateStoryExperimental, generateStoryStandard]);

  const generateImagePrompt = useCallback(async (index: number) => {
    const panel = data[index];
    if (!panel) return;

    try {
      const response = await api.generateImagePrompts({
        text: panel.panel,
        model_id_text: selectedModels.text.value,
        temperature: selectedTemperature
      });

      const promptData = JSON.parse(response.body);
      
      setData(prevData => {
        const newData = [...prevData];
        if (newData[index]) {
          newData[index].prompt = promptData.prompt;
        }
        return newData;
      });

      return promptData.prompt;
    } catch (error) {
      console.error('Error generating image prompt:', error);
      return null;
    }
  }, [api, data, selectedModels.text.value, selectedTemperature]);

  const regenerateImage = useCallback(async (index: number) => {
    try {
      const newImagesLoading = [...imagesLoading];
      newImagesLoading[index] = true;
      setImagesLoading(newImagesLoading);
      setImageData(index, "");
      setError(null);
      
      const image = await generateImageForPanel(data[index]);
      // Don't parse the image response as JSON since it's a base64 string
      setImageData(index, image.body);
    } catch (error) {
      setError(error instanceof Error ? error.message : 'An error occurred while regenerating the image');
      console.error(`Error regenerating image for panel ${index}:`, error);
    } finally {
      const newImagesLoading = [...imagesLoading];
      newImagesLoading[index] = false;
      setImagesLoading(newImagesLoading);
    }
  }, [data, imagesLoading, generateImageForPanel]);

  const setImageLoading = useCallback((index: number, isLoading: boolean) => {
    setImagesLoading(prev => {
      const newImagesLoading = [...prev];
      newImagesLoading[index] = isLoading;
      return newImagesLoading;
    });

    setData(prevData => {
      const newData = [...prevData];
      if (newData[index]) {
        newData[index].loading = isLoading;
      }
      return newData;
    });
  }, []);

  const setImageData = useCallback((index: number, image: string) => {
    setData(prevData => {
      const newData = [...prevData];
      if (newData[index]) {
        newData[index].image = image;
        if (image !== "") {
          newData[index].img_version += 1;
        }
        newData[index].loading = false;
      }
      return newData;
    });
  }, []);

  return (
    <StoryboardContext.Provider value={{
      data,
      setData,
      loading,
      error,
      setError,
      imagesLoading,
      generateStory,
      regenerateImage,
      generateImagePrompt,
      setImageLoading,
      setImageData,
      setLoading,
      generationStep,
      setGenerationStep,
      completedPanels
    }}>
      {children}
    </StoryboardContext.Provider>
  );
};

export const useStoryboard = () => {
  const context = useContext(StoryboardContext);
  if (context === undefined) {
    throw new Error('useStoryboard must be used within a StoryboardProvider');
  }
  return context;
};

import React, { createContext, useContext, useState } from 'react';

import { Character } from '../types/ai';
import { useApiManager } from '../components/ApiManager';
import { useSettings } from './SettingsContext';

interface CharacterContextType {
  characters: Character[];
  isCharacterSectionExpanded: boolean;
  setCharacters: React.Dispatch<React.SetStateAction<Character[]>>;
  setIsCharacterSectionExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  generateCharacters: (story: string) => Promise<Character[]>;
}

const CharacterContext = createContext<CharacterContextType | undefined>(undefined);

export const CharacterProvider = ({ children }: any) => {
  const [characters, setCharacters] = useState<Character[]>([]);
  const [isCharacterSectionExpanded, setIsCharacterSectionExpanded] = useState(false);
  const { selectedModels, selectedTemperature } = useSettings();
  const api = useApiManager();
  
  const extractCharacters = async (story: string) => {
    try {
      const charactersResponse = await api.extractCharacters({
        text: story,
        model_id_text: selectedModels.text.value,
        temperature: selectedTemperature
      });
      let charactersBody = JSON.parse(charactersResponse.body);
      return charactersBody.characters;
    } catch (err) {
      console.error(`Error fetching characters: ${err}`);
      return [];
    }
  };

  const generateCharacters = async (story: string) => {
    const extractedCharacters = await extractCharacters(story);
    const charactersWithIds = extractedCharacters.map((character: any, index: any) => ({
      ...character,
      id: index,
    }));
    setCharacters(charactersWithIds);
    return charactersWithIds;
  };

  return (
    <CharacterContext.Provider value={{
      characters,
      isCharacterSectionExpanded,
      setCharacters,
      setIsCharacterSectionExpanded,
      generateCharacters,
    }}>
      {children}
    </CharacterContext.Provider>
  );
};

export const useCharacters = () => {
  const context = useContext(CharacterContext);
  if (context === undefined) {
    throw new Error('useCharacters must be used within a CharacterProvider');
  }
  return context;
};

import Button from "@cloudscape-design/components/button";
import Spinner from "@cloudscape-design/components/spinner";

interface ImageComponentProps {
  loading: boolean;
  image: string | null;
  img_version: number;
  onRegenClick: () => void;
  index: number;
}
const ImageComponent = ({
  loading,
  image,
  img_version,
  onRegenClick,
  index
}: ImageComponentProps) => {

  // console.debug(`rendering image ${index} with image: ${image?.slice(-20)} (version ${img_version})`)
  return (
    <div className="image-container">
      {loading ? (
        <div className="loading-overlay">
          <Spinner size="large"/>
          <span>Generating image...</span>
        </div>
      ) : (
        <>
          {image && (
            <>
              <img 
                src={`data:image/jpeg;base64,${image}`}
                width="300px"
                alt={`Panel ${index + 1}`}
              />
              <span className="regen-icon">
                <Button variant="icon" className="regen-button" iconName="refresh" aria-label="Refresh" onClick={onRegenClick}  >
                  Refresh picture
                </Button>
              </span>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ImageComponent;

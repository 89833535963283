import { AuthenticatedUser, UserAttributes } from '../types/auth';
import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  useAuthSession,
  useSignIn,
  useSignOut,
  useTokenManagement
} from '../hooks/useAuthOperations';

import { Hub } from 'aws-amplify';

interface AuthContextType {
  user: AuthenticatedUser | null;
  userAttributes: UserAttributes | null;
  loading: boolean;
  error: Error | null;
  signOut: () => Promise<void>;
  signInWithSSO: () => Promise<AuthenticatedUser>;
  getAccessToken: () => Promise<string | null>;
  getCurrentUser: () => Promise<{ user: AuthenticatedUser; attributes: UserAttributes; }>;
}

const AuthContext = createContext<AuthContextType | null>(null);

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState<AuthenticatedUser | null>(null);
  const [userAttributes, setUserAttributes] = useState<UserAttributes | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const initialCheckDone = useRef(false);

  const { signInWithSSO } = useSignIn();
  const { signOut } = useSignOut();
  const { getCurrentUser } = useAuthSession();
  const { refreshToken, getAccessToken } = useTokenManagement();

  const checkUser = useCallback(async () => {
    try {
      const { user, attributes } = await getCurrentUser();
      setUser(user);
      setUserAttributes(attributes);
      // Only refresh token on initial check or when user changes
      if (!initialCheckDone.current) {
        await refreshToken(true);
        initialCheckDone.current = true;
      }
    } catch (error) {
      console.error('Error checking user:', error);
      setError(error as Error);
      if (!initialCheckDone.current) {
        try {
          const userData = await signInWithSSO();
          setUser(userData);
        } catch (ssoError) {
          console.error('SSO signing in failed:', ssoError);
          setError(ssoError as Error);
        }
      }
    } finally {
      setLoading(false);
    }
  }, [getCurrentUser, refreshToken, signInWithSSO]);

  useEffect(() => {
    checkUser();

    const hubListener = Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          checkUser();
          break;
        case 'signOut':
          setUser(null);
          setUserAttributes(null);
          initialCheckDone.current = false;
          break;
        default:
          break;
      }
    });

    return () => hubListener();
  }, [checkUser]);

  useEffect(() => {
    if (user) {
      const refreshInterval = setInterval(() => {
        refreshToken(false);
      }, 55 * 60 * 1000); // Refresh every 55 minutes

      return () => clearInterval(refreshInterval);
    }
  }, [refreshToken, user]);

  const handleSignOut = useCallback(async () => {
    try {
      await signOut();
      setUser(null);
      setUserAttributes(null);
      initialCheckDone.current = false;
    } catch (error) {
      console.error('Error during sign out:', error);
    }
  }, [signOut]);

  return (
    <AuthContext.Provider value={{
      user,
      userAttributes,
      loading,
      error,
      signOut: handleSignOut,
      signInWithSSO,
      getAccessToken,
      getCurrentUser
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === null) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

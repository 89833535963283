import './index.css';
import "@cloudscape-design/global-styles/index.css"
import '@aws-amplify/ui-react/styles.css';

import { Amplify } from '@aws-amplify/core';
import App from './App';
import { AuthProvider } from './contexts/AuthContext';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { config } from './config';
import reportWebVitals from './reportWebVitals';

Amplify.configure({
  Auth: {
    // Cognito User Pool configuration
    region: config.region,
    userPoolId: config.cognito.userPoolId,
    userPoolWebClientId: config.cognito.userPoolWebClientId,
    
    // OAuth/Federation configuration
    oauth: {
      domain: config.cognito.oauth.domain,
      scope: config.cognito.oauth.scope,
      redirectSignIn: config.cognito.oauth.redirectSignIn,
      redirectSignOut: config.cognito.oauth.redirectSignOut,
      responseType: config.cognito.oauth.responseType,
      providers: ['Federate']
    },

    // Auth configuration
    authenticationFlowType: 'USER_SRP_AUTH'
  },
  
  // API Gateway configuration
  API: {
    endpoints: config.api.endpoints.map(endpoint => ({
      ...endpoint,
      custom_header: async () => {
        try {
          const session = await Amplify.Auth.currentSession();
          const token = session.getIdToken().getJwtToken();
          return {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            'X-Amz-Date': new Date().toISOString()
          };
        } catch (e) {
          console.error('Error getting session:', e);
          return {};
        }
      }
    }))
  }
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>
);

reportWebVitals();

import * as React from "react";

import Button from "@cloudscape-design/components/button"
import CharacterItem from "./CharacterItem";

// TODO: change "any"
const CharacterManager = (props: any) => {

    const updateCharacter = (characterId: any, updatedProps: any) => {
        console.log(`characterId: ${characterId}`)
        console.log(`updatedProps: ${updatedProps}`)

        const newCharacters = props.characters.map((char: any) => {
            if(char.id === characterId) {
                return {
                    ...char,
                    ...updatedProps
                }
            }
            return char; 
        });

        props.setCharacters(newCharacters);
    }

    // const extractCharacters = async (story) => {
    //     console.log(`Extracting characters`)
    //     const apiName = 'genai';
    //     const path = 'extract-characters/';
    //     const myInit = { // OPTIONAL
    //         headers: {},
    //         body: {
    //             text: story,
    //             model_id_text: props.selectedTextModel.value,
    //             temperature: props.selectedTemperature
    //         }
    //     }
    //     // setLoading(true)
    //     try {
    //         const charactersResponse = await API.post(apiName, path, myInit);
    //         let charactersBody = JSON.parse(charactersResponse.body)
    //         console.log(`panelsResponse: ${charactersBody.characters}`);
    //         // setLoading(false)
    //         props.setCharacters(charactersBody.characters)
    //         return charactersBody.characters
    //     } catch (err) {
    //         console.error(`Error fetching data: ${err}`);
    //         // setLoading(false);
    //     } finally {
    //         // setLoading(false);
    //     }
    //     return null
    // }
    const handleRegenerate = async () => {
        console.log(`Regenerating story`);
        console.log(`props: ${JSON.stringify(props)}`);
        props.generate("", false);
    }
    return (
        <>
        {/* <Button onClick={() => extractCharacters(props.customText)}>
            Generate characters
        </Button> */}
            <ul className="data-list">
                {props.characters?.map((character: any) => (
                    <CharacterItem
                        key={character.id}
                        character={character}
                        updateCharacter={updateCharacter}
                    /> 
                ))}
            </ul>
            <Button onClick={handleRegenerate}>
                Re-generate your story
            </Button>
        </>
    );
};

export default CharacterManager;
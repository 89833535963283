import { AuthenticatedUser, UserAttributes } from '../types/auth';
import { useCallback, useRef } from 'react';

import { Auth } from 'aws-amplify';

export const useSignIn = () => {
  const signInWithSSO = async (): Promise<AuthenticatedUser> => {
    try {
      const user = await Auth.federatedSignIn();
      console.log("signInWithSSO OK", user);
      return user as AuthenticatedUser;
    } catch (e) {
      console.error("signInWithSSO ERROR", e);
      throw e;
    }
  };

  return { signInWithSSO };
};

export const useSignOut = () => {
  const signOut = async (): Promise<void> => {
    try {
      await Auth.signOut();
    } catch (e) {
      console.error("signOut ERROR: ", e);
      throw e;
    }
  };

  return { signOut };
};

export const useAuthSession = () => {
  const getCurrentUser = useCallback(async (): Promise<{
    user: AuthenticatedUser,
    attributes: UserAttributes
  }> => {
    try {
      const userData = await Auth.currentAuthenticatedUser();
      return {
        user: userData,
        attributes: userData.signInUserSession.idToken.payload,
      };
    } catch (e) {
      console.error("Error getting current user: ", e);
      throw e;
    }
  }, []);

  return { getCurrentUser };
};

export const useTokenManagement = () => {
  const accessTokenRef = useRef<string | null>(null);
  const lastRefreshTime = useRef<number>(0);
  const REFRESH_THRESHOLD = 5 * 60 * 1000; // 5 minutes in milliseconds

  const refreshToken = useCallback(async (force: boolean = false): Promise<string | null> => {
    const now = Date.now();
    // Only refresh if forced or if enough time has passed since last refresh
    if (!force && lastRefreshTime.current && (now - lastRefreshTime.current < REFRESH_THRESHOLD)) {
      return accessTokenRef.current;
    }

    try {
      const currentSession = await Auth.currentSession();
      const newAccessToken = currentSession.getAccessToken().getJwtToken();
      accessTokenRef.current = newAccessToken;
      lastRefreshTime.current = now;
      return newAccessToken;
    } catch (error) {
      console.error('Error refreshing token:', error);
      accessTokenRef.current = null;
      return null;
    }
  }, []);

  const getAccessToken = useCallback(async (): Promise<string | null> => {
    if (!accessTokenRef.current) {
      return await refreshToken(true);
    }
    return accessTokenRef.current;
  }, [refreshToken]);

  return {
    accessToken: accessTokenRef.current,
    refreshToken,
    getAccessToken
  };
};
